@font-face {
    font-family: 'AvenirLTProBook';
    src: url('/fonts/MCL/AvenirLTProBook.eot');
    src: url('/fonts/MCL/AvenirLTProBook.eot') format('embedded-opentype'), url('/fonts/MCL/AvenirLTProBook.woff2') format('woff2'), url('/fonts/MCL/AvenirLTProBook.woff') format('woff'), url('/fonts/MCL/AvenirLTProBook.ttf') format('truetype'), url('/fonts/MCL/AvenirLTProBook.svg#AvenirLTProBook') format('svg');
}

@font-face {
    font-family: 'AvenirLTProHeavy';
    src: url('/fonts/MCL/AvenirLTProHeavy.eot');
    src: url('/fonts/MCL/AvenirLTProHeavy.eot') format('embedded-opentype'), url('/fonts/MCL/AvenirLTProHeavy.woff2') format('woff2'), url('/fonts/MCL/AvenirLTProHeavy.woff') format('woff'), url('/fonts/MCL/AvenirLTProHeavy.ttf') format('truetype'), url('/fonts/MCL/AvenirLTProHeavy.svg#AvenirLTProHeavy') format('svg');
}

@font-face {
    font-family: 'AvenirLTProLight';
    src: url('/fonts/MCL/AvenirLTProLight.eot');
    src: url('/fonts/MCL/AvenirLTProLight.eot') format('embedded-opentype'), url('/fonts/MCL/AvenirLTProLight.woff2') format('woff2'), url('/fonts/MCL/AvenirLTProLight.woff') format('woff'), url('/fonts/MCL/AvenirLTProLight.ttf') format('truetype'), url('/fonts/MCL/AvenirLTProLight.svg#AvenirLTProLight') format('svg');
}

@font-face {
    font-family: 'AvenirLTProMedium';
    src: url('/fonts/MCL/AvenirLTProMedium.eot');
    src: url('/fonts/MCL/AvenirLTProMedium.eot') format('embedded-opentype'), url('/fonts/MCL/AvenirLTProMedium.woff2') format('woff2'), url('/fonts/MCL/AvenirLTProMedium.woff') format('woff'), url('/fonts/MCL/AvenirLTProMedium.ttf') format('truetype'), url('/fonts/MCL/AvenirLTProMedium.svg#AvenirLTProMedium') format('svg');
}






